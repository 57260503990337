import React from "react";
import img from "../Images/iikon-icon.svg";
import { NavLink } from "react-router-dom";
import { SCMainNavigation } from "./styles";
import { SCHeaderStyles, SCLineThrough } from "./styles";

function Header() {
    return (
      <SCHeaderStyles>
        <SCMainNavigation>
          <img src={img} alt="iikon-icon" />

          <NavLink
            to="/home"
            style={{
              textDecoration: "none",
              color: "rgb(230, 222, 222)"
            }}
          >
              <span>HOME</span>
          </NavLink>

          <NavLink
            to="/about"
            style={{
              textDecoration: "none",
              color: "rgb(230, 222, 222)"
            }}
          >
            <span>ABOUT</span>
          </NavLink>
          <NavLink
            to="/contact"
            style={{
              textDecoration: "none",
              color: "rgb(230, 222, 222)"
            }}
          >
            <span>CONTACT</span>
          </NavLink>
        </SCMainNavigation>
      </SCHeaderStyles>
    
    )
          }
    export default Header;
