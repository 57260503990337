import styled from "styled-components/macro";

export const SCFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 90.6vh; */
  /* max-height: 90.6vh; */
  max-width: 100vw;
  overflow-y: hidden; // hide vertical
  overflow-x: hidden; // hide horizontal
  margin: 0;
  padding: auto;
  margin: auto;
  background-color: rgb(145, 143, 143);
  color: rgb(230, 222, 222);

  img {
    margin-top: 14rem;
    margin-bottom: 15rem;
    /* Small devices (landscape phones, 576px and up) */
    @media (max-width: 500px) {
      justify-content: center;
      width: 20%;
      height: auto;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (max-width: 576px) {
      justify-content: center;
      width: 50%;
      height: auto;
      /* border: 1px solid red; */
    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 768px) {
      width: 80%;
      height: auto;
      margin-bottom: 1rem;
      /* border: 1px solid violet; */
    }
    /* // Large devices (desktops, 992px and up) */
    @media (max-width: 992px) {
      width: 90%;
      height: auto;
      margin-bottom: 2rem;
      /* border: 1px solid purple; */
    }

    /* // Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
      /* border: 1px solid brown; */
      margin-top: 10rem;
      margin-bottom: 12rem;
    }
  }

  p {
    margin-top: 5rem;
    margin-bottom: 15rem;

    /* Small devices (landscape phones, 576px and up) */
    @media (max-width: 500px) {
      justify-content: center;
      width: 20%;
      height: auto;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (max-width: 576px) {
      justify-content: center;
      width: 50%;
      height: auto;
    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 768px) {
      /* justify-content: center; */
      width: 80%;
      height: auto;
    }
    /* // Large devices (desktops, 992px and up) */
    @media (max-width: 992px) {
      width: 96%;
      height: auto;
      margin-bottom: 12rem;
    }

    /* // Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
    }
  }
`;

//Kopierad från abotu/styles.js
export const SCAboutText = styled.div`
  display: flex;
  place-content: center;
  text-transform: none;
  font-size: 1.2rem;
  p {
    max-width: 60%;
  }

  @media only screen and (min-width: 0px) and (max-width: 576px) {
    font-size: 0.1;
    p {
      font-size: 0.8rem;
      max-width: 75%;
      /* margin-bottom: 4.5rem; */
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 768px) {
    font-size: 0.8;
    p {
      font-size: 1rem;
      max-width: 75%;
    }
  }
`;
