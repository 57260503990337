import styled from "styled-components/macro";
import "../Styles/fonts.css";

// export const SCFlex = styled.div`
//   display: flex;
//   height: 80vh;
//   margin: auto;
//   background-color: rgb(145, 143, 143);
//   color: rgb(230, 222, 222);
//   overflow: hidden;
//   h1 {
//     justify-content: center;
//   }
// `;

//Från home styles
export const SCFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 90.6vh; */
  /* max-height: 90.6vh; */
  max-width: 100vw;
  overflow-y: hidden; // hide vertical
  overflow-x: hidden; // hide horizontal
  margin: 0;
  padding: auto;
  margin: auto;
  background-color: rgb(145, 143, 143);
  color: rgb(230, 222, 222);
`;

export const SCTextStyle = styled.div`
  font-family: "Hind Guntur", sans-serif;
`;

export const SCAboutText = styled.div`
  display: flex;
  place-content: center;
  text-transform: none;

  h1 {
    text-transform: uppercase;
    font-family: "Hind Guntur", sans-serif;
    font-size: 1.3rem;
    letter-spacing: 0.2rem;
    font-weight: 300;
  }
  p {
    max-width: 50%;
    font-family: "Hind Guntur", sans-serif;
    letter-spacing: 0.1rem;
    font-size: 1.1rem;
    margin: 1rem;
    line-height: 1.7rem;
    font-weight: 300;
    /* line-height: 2rem; */
  }

  @media only screen and (min-width: 0px) and (max-width: 576px) {
    p {
      font-size: 0.8rem;
      max-width: 75%;
      line-height: 1.5rem;
      /* margin-bottom: 4.5rem; */
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 768px) {
    p {
      font-size: 1rem;
      max-width: 75%;
      line-height: 1.6rem;
    }
  }
`;
