import React from "react";
import Header from "../Header";
import { SCAboutText } from "./styles";
import { SCFlex } from "../Home/styles";

import { SCWrapper, SCFlexColumn, SCSectionTitles } from "../Styles/general";

const About = () => {
  return (
    <SCWrapper>
      {/* <SCHeaderStyles> */}
      <Header />
      {/* </SCHeaderStyles> */}

      <SCFlex>
        <SCFlexColumn>
          <SCSectionTitles>
            <h1>ABOUT</h1>
          </SCSectionTitles>

          <SCAboutText>
            <p>
              IKON Music is the creative base founded by Simon Janlöv in 2017.
              Specializing in writing and producing music for the Asian pop
              market, the young company has in its first years provided hit
              music for multiple artists across Asia.
              <br />
              <br />
              Simon Janlöv has seven years of experience as a songwriter and
              music producer. So far his songs has sold over 8 million copies
              and has been certified 25 gold and platinum awards.
              <br /> The artists Simon has written for include: Arashi, EXO-CBX,
              Generations from EXILE Tribe, Monsta X, SHINee, E-Girls, VIXX,
              Girls Generation and more.
              <br />
              <br /> IIKON Music is based in Stockholm, Sweden.
            </p>
          </SCAboutText>
        </SCFlexColumn>
      </SCFlex>
    </SCWrapper>
  );
};

export default About;
