import React, { useState, useEffect } from "react";
import { SCTextStyle } from "./styles";
import { SCWrapper, SCSectionTitles } from "../Styles/general";
import { SCFlex } from "../Home/styles";
import { SCFlexColumn } from "../Styles/general";
import Header from "../Header";
import "../Styles/fonts.css";

//MATERIAL-UI
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

// eslint-disable-next-line
const theme = createMuiTheme({
  palette: {
    primary: grey,
    secondary: {
      main: "#757575"
    }
  },

  typography: {
    fontFamily: [
      "Quicksand",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  }
});

const useStyles = makeStyles(theme => ({
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "rgb(230, 222, 222) !important"
  },
  root: {
    fontFamily: "Quicksand",
    "&focused": {
      borderColor: theme.palette.secondary.main
    },
    color: grey
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    "&:focus": {
      borderColor: theme.palette.secondary.main,
      background: theme.palette.secondary.main
    },
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
    // background: 'red'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.main
    }
  }
}));

export default function ContactForm() {
  const classes = useStyles();

  const [message, setMessage] = useState({ sender: "", message: "" });

  useEffect(() => {
    console.log("Mitt emailstate " + message.sender);
  }, [message.sender]);

  useEffect(() => {
    console.log("Mitt meddeladestate " + message.message);
  }, [message.message]);

  const [messageText, setMessageText] = useState("");

  const handleEmailInput = event => {
    setMessage({
      ...message,
      sender: [event.target.value]
    });

    event.preventDefault();
  };

  const handleSubmit = event => {
    setMessage({
      ...message,
      message: [...message.students, messageText]
    });
    event.preventDefault();
  };

  return (
    <SCWrapper>
      <div className="page">
        <Header />

        <SCFlex>
          <SCTextStyle>
            <SCFlexColumn>
              <SCSectionTitles>
                <h1>CONTACT</h1>
              </SCSectionTitles>
              <Container component="main" maxWidth="xs">
                {/* <CssBaseline /> */}
                <div className={classes.paper}>
                  <form
                    onSubmit={handleSubmit}
                    className={classes.form}
                    action="https://formspree.io/simon.janlov@gmail.com"
                    method="POST"
                    // noValidate
                    required
                  >
                    <TextField
                      onChange={event => handleEmailInput(event)}
                      value={message.sender}
                      focused="false"
                      asterisk="false"
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="email"
                      name="email"
                      autoComplete="email"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      InputLabelProps={{
                        style: { color: "rgb(230, 222, 222)" }
                      }}
                    />

                    <TextField
                      onChange={event => setMessageText(event.target.value)}
                      value={messageText}
                      asterisk={false}
                      required
                      multiline={true}
                      rows={6}
                      rowsMax={8}
                      variant="outlined"
                      margin="normal"
                      // isRequired={true}
                      fullWidth
                      name="message"
                      label="Your Message"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      InputLabelProps={{
                        style: { color: "rgb(230, 222, 222)" }
                      }}
                    />

                    <Button
                      type="submit"
                      value="Submit"
                      onSubmit={handleSubmit}
                      fullWidth
                      className={classes.submit}
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "gray"
                      }}
                      variant="contained"
                    >
                      Send
                    </Button>
                  </form>
                </div>
                <Box mt={8}></Box>
              </Container>
            </SCFlexColumn>
          </SCTextStyle>
        </SCFlex>
      </div>
    </SCWrapper>
  );
}
