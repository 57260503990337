import styled from "styled-components/macro";

import "../Styles/fonts.css";

export const SCLineThrough = styled.div`
  text-decoration: line-through;
`;

export const SCHeaderStyles = styled.div`
  background-color: rgb(145, 143, 143);
  color: rgb(230, 222, 222);
  height: 8.5vh;
  /* border: 1px solid pink; */
  font-family: "Hind Guntur", sans-serif;
  font-weight: 300;
  span:hover {
    /* font-weight: 500; */
    color: rgb(82, 80, 77);
  }

  img {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    height: 7vh;
    margin-right: 2rem;
    margin-top: 0.5rem;
    /* border: 1px solid white; */
  }

  @media only screen and (min-width: 0px) and (max-width: 320px) {
    font-size: 0.1;
    img {
      /* border: 1px solid gold; */
      height: 4.1vh;
      margin-top: 2rem;
      margin-right: 0.8rem;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 0.1;
    img {
      /* border: 1px solid palegreen; */
      height: 4.6vh;
      margin-top: 2.2rem;
      margin-right: 1.4rem;
      /* padding-right: 0.9rem; */
      /* display: none; */
    }
  }
  @media only screen and (min-width: 577px) and (max-width: 767px) {
    font-size: 3;
    img {
      /* border: 1px solid purple; */
      height: 5.6vh;
      margin-top: 1.8rem;
      margin-right: 2.2rem;
    }
  }
  @media only screen and (min-width: 767px) and (max-width: 1000px) {
    font-size: 3;
    img {
      /* border: 1px solid lightblue; */
      height: 6.5vh;
      margin-top: 2rem;
      /* padding-left: 3.5rem; */
      margin-right: 2.5rem;
    }
  }
  @media only screen and (min-width: 1000px) and (max-width: 1500px) {
    font-size: 0.1;
    img {
      /* border: 1px solid yellow; */
      height: 6.5vh;
      margin-top: 1.9rem;
      margin-right: 5rem;
    }
  }
  @media only screen and (min-width: 1500px) and (max-width: 3000px) {
    font-size: 0.1;
    img {
      /* border: 1px solid orange; */
      margin-top: 2.5rem;
      height: 6vh;
      /* margin-top: 1.4rem; */
      margin-right: 5.5rem;
      /* padding-right: 25rem; */
    }
  }
`;

export const SCMainNavigation = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;

  a,
  span {
    white-space: nowrap;
    /* align-items: flex-end; */
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 4rem;
    /* margin-bottom: 5rem; */
  }
  /* span:hover {
    font-weight: 500;
  } */
  @media only screen and (min-width: 0px) and (max-width: 350px) {
    span,
    a {
      font-size: 0.9rem;
      /* border: 1px solid pink; */
      padding: 2.4rem 0.2rem 0rem 0.2rem;
    }
  }
  @media only screen and (min-width: 350px) and (max-width: 576px) {
    span,
    a {
      font-size: 1.1rem;
      /* border: 1px solid black; */

      padding: 2.5rem 0.4rem 0rem 0.4rem;
    }
  }

  @media only screen and (min-width: 577px) and (max-width: 767px) {
    span,
    a {
      font-size: 1.1rem;
      /* border: 1px solid lightgreen; */
      padding: 2.5rem 1rem 0rem 1rem;
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1000px) {
    span,
    a {
      font-size: 1.2rem;

      padding: 2.7rem 1.5rem 0rem 2rem;
      letter-spacing: 0;
      /* border: 1px solid darkgreen; */
    }
  }

  @media only screen and (min-width: 1000px) and (max-width: 1500px) {
    span,
    a {
      font-size: 1.2rem;
      padding: 3rem 2.7rem 0rem 2.7rem;
      /* border: 1px solid rebeccapurple; */
    }
  }

  @media only screen and (min-width: 1500px) and (max-width: 3000px) {
    span,
    a {
      /* border: 1px solid yellow; */
      font-size: 1.4rem;
      padding: 3.5rem 4.5rem 0rem 4.5rem;
    }
  }
`;

export const SCMainNavigationStyles = styled.div`
  /* position: static; */
  /* margin-top: 20rem; */
  /* padding-bottom: 12rem; */
  font-family: Arial, Helvetica, sans-serif;
`;

export const SCPositionAbsolute = styled.div`
  /* position: fixed; */
`;
