import styled from "styled-components/macro";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600');
@import "../Styles/fonts.css";
  
  body {
    padding: 0;
    margin: 0;
    
    /* font-family: Raleway, sans-serif;
    background: rgb(247, 245, 237); */
  
    }  `;

export const SCWrapper = styled.div`
  height: 100vh;
  background-color: rgb(145, 143, 143);
`;

export const SCFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 2.5rem;
    margin-top: 6rem;
    align-self: center;
    text-transform: uppercase;
    font-family: "Hind Guntur", sans-serif;
    font-weight: 400;
    /* letter-spacing: 0.2rem; */
    /* margin-bottom: 20rem; */
    /* padding-bottom: 2rem; */
  }
  @media only screen and (min-width: 0px) and (max-width: 576px) {
    h1 {
      /* padding-top: 4rem; */
      /* margin-bottom: 2.5rem; */
      margin-top: 4rem;
      margin-bottom: 0;
      font-size: 2rem;
    }
  }

  @media only screen and (min-width: 577px) and (max-width: 767px) {
    h1 {
      /* padding-top: 4rem; */
      /* margin-bottom: 2.5rem; */
      margin-top: 5rem;
      margin-bottom: 0;
      font-size: 2rem;
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1000px) {
    h1 {
      /* padding-top: 4rem; */
      /* margin-bottom: 2.5rem; */
      margin-top: 5.5rem;
      margin-bottom: 0;
      font-size: 2rem;
    }
  }
`;
export const SCTextStyle = styled.div`
  /* Not in use - remove */
  text-transform: uppercase;
  font-family: "Quicksand", sans-serif;
  /* font-family: Arial, Helvetica, sans-serif; */
  letter-spacing: 0.2rem;
  /* font-family: 'Times New Roman', Times, serif; */
`;

export const SCSectionTitles = styled.div`
  align-self: center;
  font-size: 1.3rem;
  font-family: "Quicksand", sans-serif;
  letter-spacing: 0.3rem;
  /* font-weight: bold; */

  @media only screen and (min-width: 0px) and (max-width: 350px) {
    h1 {
      /* font-size: 1.3rem; */
      /* font-weight: 300; */
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    h1 {
      font-size: 2rem;
      /* font-weight: 300; */
    }
  }
`;
