import React from "react";
import { render } from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import Container from "./Components/Container";

const App = () => (
  <Router>
    <div className="media">
      <Container />
    </div>
  </Router>
);

render(<App />, document.getElementById("root"));
