import React from "react";

import { SCWrapper } from "../Styles/general";
import { SCFlex } from "./styles";
import img from "../Images/iikon-logo-post-transition.svg";
import Header from "../Header";

const Home = () => {
  return (
    <SCWrapper>
      <Header />
      <SCFlex>
        <img src={img} alt="iikon-landing-logo-post-transition" />
      </SCFlex>
    </SCWrapper>
  );
};

export default Home;
