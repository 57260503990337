import styled from "styled-components/macro";
import "../Styles/fonts.css";

export const SCFlex = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  margin: auto;
  background-color: rgb(145, 143, 143);
  color: rgb(230, 222, 222);
`;

export const SCTextStyle = styled.div`
  text-transform: uppercase;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: "Quicksand", sans-serif;
  letter-spacing: 0.3rem;
  padding: 0;
`;

export const SCContactParagraph = styled.div`
  margin: 0;
  padding: 0;
`;

export const SCFormStyle = styled.div`
  form {
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
    text-transform: uppercase;
  }
  input {
    margin-top: 1rem;
    justify-content: space-between;
    align-self: center;
    width: 20rem;
    max-width: 100%;
    height: 2rem;
    text-transform: uppercase;
    background-color: rgb(230, 222, 222);
    border-style: none;
  }

  textarea {
    margin-top: 1rem;
    text-transform: uppercase;
    /* font-family: Arial, Helvetica, sans-serif; */
    background-color: rgb(230, 222, 222);
  }

  /* @media only screen and (min-width: 0px) and (max-width: 576px) {
    form,
    input,
    textarea {
      justify-content: center;
      width: 97%;
      align-items: center;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 768px) {
    form {
      font-size: 0.7rem;
      max-width: 75%;
    }
  } */

  @media only screen and (min-width: 0px) and (max-width: 350px) {
    form,
    input,
    textarea {
      border: 1px solid gold;
      height: 2vh;
      margin-top: 1rem;
      margin-right: 1rem;
    }
  }
  @media only screen and (min-width: 350px) and (max-width: 576px) {
    form,
    input,
    textarea {
      border: 1px solid white;
    }
  }
  @media only screen and (min-width: 577px) and (max-width: 767px) {
    form,
    input,
    textarea {
      border: 1px solid purple;
    }
  }
  @media only screen and (min-width: 767px) and (max-width: 1000px) {
    form,
    input,
    textarea {
      border: 1px solid lightblue;
    }
  }
  @media only screen and (min-width: 1000px) and (max-width: 1500px) {
    form,
    input,
    textarea {
      border: 1px solid yellow;
    }
  }
  @media only screen and (min-width: 1500px) and (max-width: 3000px) {
    form,
    input,
    textarea {
      border: 1px solid orange;
    }
  }
`;
//
//
//
//
//
//
//
//
//
//
//
//

export const SCSubmitButton = styled.div`
  display: flex;
  input {
    justify-content: center;
    color: rgb(145, 143, 143);
    background-color: rgb(230, 222, 222);
    padding: 12px 22px;
    text-align: center;
    font-size: 10px;
    width: 5rem;
    height: 0.5rem;
    max-height: 3rem;
    max-width: 100%;
  }
`;
